
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.panel-channels-switcher,
[class*='panel-channels-switcher--'] {
  // overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem 0;
  background: $white;

  @include mq(m) {
    padding: $spacing * 3 $spacing * 4;
  }
}

.panel-channels-switcher__title {
  @extend %fw-semi;

  font-family: $ff-alt;

  @include mq(m) {
    margin-bottom: $spacing * 2;
  }

  @include mq($until: m) {
    text-align: center;
  }
}

.panel-channels-switcher__text {
  @include mq($until: m) {
    text-align: center;
  }
}

.panel-channels-switcher__switcher-outer {
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  padding: $spacing 0;
  background-color: $white;

  @include mq(m) {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
}

.panel-channels-switcher__switcher {
  padding: 4px 0;

  @include mq($until: l) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.panel-channels-switcher__items {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 3;
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq(xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.panel-channels-switcher__item {
  @include aspect-ratio(130, 94);

  position: relative;
  border: 1px solid $c-gray-lightest;
  border-radius: 4px;

  &[data-light='false'] {
    .is-light & {
      display: none;
    }
  }

  img {
    @include get-all-space;

    object-fit: contain;
    transition: opacity 0.3s $ease-out-quart;
  }
}
